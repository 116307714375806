<template>
  <div>
    <div v-if="requests.length > 0" class="mb-5">
      <div>Organisations I have requested to join</div>
      <b-table
        :items="requests"
        :fields="requestFields"
        primary-key="id"
        striped
        sort-icon-left
      >
        <template
          v-for="field in dynamicsRequestFields(requestFields)"
          v-slot:[`head(${field.key})`]
        >
          {{ field.label }}
        </template>
      </b-table>
    </div>
    <div v-if="requests.length > 0">Organisations I'm a part of</div>
    <b-table
      :busy="isBusy"
      :items="orgProvider"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :show-empty="true"
      primary-key="id"
      sort-by="name"
      striped
      sort-icon-left
      stacked="sm"
    >
      <template #table-busy>
        <div class="text-center text-default my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template v-slot:empty>
        <p>You aren't part of any organisations yet.</p>
        <p>
          If you would like to share and collaborate with your team members or
          colleagues, you can create an organisation below.
        </p>
      </template>
      <template
        v-for="field in dynamicFields(fields)"
        v-slot:[`head(${field.key})`]
      >
        {{ field.label }}
      </template>
      <template v-slot:cell(name)="data">
        <router-link
          :to="{
            name: 'OrgDetails',
            params: {organisationId: data.item.id},
          }"
        >
          {{ data.item.name }}
        </router-link>
      </template>
      <template v-slot:cell(canSubmit)="data">
        <div class="col-lg-10 text-center stacked-left-align">
          <check-icon :show="data.item.canSubmit" />
        </div>
      </template>
    </b-table>

    <Pagination
      :number-of-elements="numberOfElements"
      :total-rows="totalRows"
      :current-page="currentPage"
      :per-page="perPage"
      @update-current-page="updateCurrentPage"
    />

    <div class="mt-5">
      <router-link :to="{name: 'OrgCreate'}" class="btn btn-primary">
        New Organisation
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapGetters} from "vuex";
import CheckIcon from "../interface/CheckIcon";
import Pagination from "../interface/Pagination";
import handleApiError from "../../shared/apiErrorUtil";

export default {
  name: "Organisations",
  components: {CheckIcon, Pagination},
  computed: {
    ...mapState({
      orgs: state => state.orgStore.orgs,
      isLoading: state => state.orgStore.isLoading,
    }),
  },
  data() {
    return {
      isBusy: false,
      requests: [],
      requestFields: [
        {key: "orgName", label: "Organisation Name", sortable: true},
        {key: "message", label: "Message"},
        {
          key: "dateRequested",
          label: "Date Requested",
          formatter: this.$options.filters.formatDate,
          sortable: true,
        },
      ],
      fields: [
        {key: "name", label: "Organisation Name", sortable: true},
        {key: "displayGroup", label: "Role", sortable: true},
        {key: "access", label: "Submission Access", sortable: true},
        {key: "canSubmit", label: "Can Submit", sortable: true},
      ],
      currentPage: 1,
      perPage: 15,
      totalRows: 0,
      numberOfElements: 0,
      apiError: false,
    };
  },
  mounted() {
    this.fetchOrgs().catch(error => {
      if (!this.apiError) {
        this.apiError = true;
        handleApiError(error, this);
      }
    });
    const userId = this.getCurrentUser().id;
    this.fetchJoinRequestsByUser(userId)
      .then(response => {
        if (response.data.content) {
          const requests = response.data.content;
          this.requests.splice(0, this.requests.length, ...requests);
        } else {
          console.log("No content received");
        }
      })
      .catch(error => {
        if (!this.apiError) {
          this.apiError = true;
          handleApiError(error, this);
        }
      });
  },
  methods: {
    ...mapActions("orgStore", ["fetchOrgs", "fetchOrgsWithPageRequest"]),
    ...mapActions("joinRequestStore", ["fetchJoinRequestsByUser"]),
    ...mapGetters("auth", ["isViewerOf", "getCurrentUser"]),
    ...mapGetters("role", ["getNameForGroup"]),
    orgProvider(ctx) {
      const sortDir = ctx.sortDesc ? "desc" : "asc";
      const pageRequest = {
        query: {
          params: {
            page: ctx.currentPage - 1,
            size: ctx.perPage,
            sort: ctx.sortBy + "," + sortDir,
          },
        },
      };
      this.isBusy = true;
      return this.fetchOrgsWithPageRequest(pageRequest)
        .then(response => {
          this.isBusy = false;
          const data = response.data;
          this.totalRows = data.totalElements;
          this.numberOfElements = data.numberOfElements;
          return data.content;
        })
        .then(this.formatOrgs)
        .catch(error => {
          if (!this.apiError) {
            this.apiError = true;
            handleApiError(error, this);
          }
        });
    },
    updateCurrentPage(currentPage) {
      this.currentPage = currentPage;
    },
    formatOrgs(orgs) {
      const roles = new Map(
        this.getCurrentUser().roles.map(role => [role.org.id, role])
      );

      return orgs.map(org => {
        const role = roles.get(org.id);
        return {
          ...org,
          displayGroup: role.groups
            .filter(group => group !== "SUBMITTER")
            .map(group => this.getNameForGroup()(group))
            .join(", "),
          canSubmit: role.groups.indexOf("SUBMITTER") >= 0,
          access: role.grantAppVisibility === "FULL" ? "All" : "Limited",
        };
      });
    },
    dynamicFields(fields) {
      const hasAbn = this.orgs.filter(org => org.abn != null).length > 0;
      const hasNzbn = this.orgs.filter(org => org.nzbn != null).length > 0;

      if (hasAbn && fields.filter(f => f.key === "abn").length === 0) {
        fields.splice(1, 0, {key: "abn", label: "ABN", sortable: true});
      }
      if (hasNzbn && fields.filter(f => f.key === "nzbn").length === 0) {
        let index = hasAbn ? 2 : 1;
        fields.splice(index, 0, {key: "nzbn", label: "NZBN", sortable: true});
      }

      return fields;
    },
    dynamicsRequestFields(fields) {
      const hasAbn = this.requests.filter(r => r.orgAbn != null).length > 0;
      const hasNzbn = this.requests.filter(r => r.orgNzbn != null).length > 0;

      if (hasAbn && fields.filter(f => f.key === "orgAbn").length === 0) {
        fields.splice(1, 0, {key: "orgAbn", label: "ABN", sortable: true});
      }
      if (hasNzbn && fields.filter(f => f.key === "orgNzbn").length === 0) {
        let index = hasAbn ? 2 : 1;
        fields.splice(index, 0, {
          key: "orgNzbn",
          label: "NZBN",
          sortable: true,
        });
      }

      return fields;
    },
  },
};
</script>

<style scoped lang="scss">
$screen-sm-width: 576px;
@media (max-width: $screen-sm-width) {
  ::v-deep .b-table-stacked-sm .stacked-left-align {
    text-align: left !important;
    padding-left: 0;
  }
}
</style>
