<template>
  <div v-if="showPageControl">
    <span class="paginationCount">
      Showing {{ firstItemNumber }}-{{
        firstItemNumber + numberOfElements - 1
      }}
      of {{ totalRows }}
    </span>
    <b-pagination
      v-model="currentPageVar"
      :total-rows="totalRows"
      :per-page="perPage"
      align="center"
      hide-goto-end-buttons
    />
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: ["numberOfElements", "totalRows", "currentPage", "perPage"],
  computed: {
    showPageControl() {
      return this.totalRows > this.perPage;
    },
    firstItemNumber() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
  },
  data() {
    return {
      currentPageVar: 1,
    };
  },
  mounted() {
    this.currentPageVar = this.currentPage;
  },
  watch: {
    currentPageVar: function (currentPage) {
      this.$emit("update-current-page", currentPage);
    },
  },
};
</script>
<style scoped lang="scss">
.paginationCount {
  float: left;
}
</style>
